import request from './request'

const api = {
	// 获取列表
	lists: '/bonus/lists',
	// 添加
	add: '/bonus/add',
	// 删除
	dele: '/bonus/del',
	// 编辑
	edit: '/bonus/update',
	// 详情
	info: '/bonus/read',
	// 申请列表
	bonusapply: '/bonusapply/lists',
	// 申请详情
	bonusapplyread: '/bonusapply/read',
	// 申请 删除
	applydele: '/bonusapply/del',
	// 申请编辑
	applyupdate: '/bonusapply/update',
	// 村
	cunlist: '/village/lists',
	// 添加
	adds: '/bonusapply/add',
}

export {
	request,
	api
}
