<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" title="查看材料">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="村">
						<span class="xx"></span>
						<el-input clearable disabled v-model="ruleForm.village_name"></el-input>
					</el-form-item>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input clearable disabled v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx"></span>
						<el-input clearable disabled v-model="ruleForm.tel"></el-input>
					</el-form-item>
					<el-form-item label="身份证">
						<span class="xx"></span>
						<el-input clearable disabled v-model="ruleForm.idcard"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable disabled v-model="ruleForm.remarks"></el-input>
					</el-form-item>
					<el-form-item label="申请表">
						<span class="xx"></span>
						<div class="dialog-up">
							<div class="dialog-up-left">
								<div class="upload-li" v-for="(item,index) in FilList" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="申请表图片">
						<span class="xx"></span>
						<div class="dialog-up">
							<div v-for="(item,index) in ImgList" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="ImgList"
									:initial-index="index" fit="fill">
								</el-image>
							</div>
					
						</div>
					</el-form-item>
					<el-form-item label="工作证明原件">
						<span class="xx"></span>
						<div class="dialog-up">
							<div v-for="(item,index) in zmList" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="zmList"
									:initial-index="index" fit="fill">
								</el-image>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="工资证明原件">
						<span class="xx"></span>
						<div class="dialog-up">
							<div v-for="(item,index) in gzList" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="gzList"
									:initial-index="index" fit="fill">
								</el-image>
							</div>
						</div>
					</el-form-item>

				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/bonus";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {},
				FilList: [],
				zmList:[],
				gzList:[],
				ImgList:[]
			}
		},
		methods: {
			// 下载
			open(url) {
				window.location.href = url
			},
			// 获取信息
			getinfo(id) {
				this.$http.post(api.bonusapplyread, {
						id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.FilList = res.data.apply_sheet?JSON.parse(res.data.apply_sheet):[]
							this.zmList = res.data.work_certificate?res.data.work_certificate.split(","):[]
							this.gzList = res.data.salary_certificate?res.data.salary_certificate.split(","):[],
							this.ImgList = res.data.apply_imgpath?res.data.apply_imgpath.split(","):[]
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.getinfo(id)
				this.outerVisible = !this.outerVisible
			}
		}
	}
</script>
<style scoped lang="scss">
	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
		height: 20px;
		line-height: 20px;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
